/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine,
    ResponsiveContainer,
    Brush,
  } from "recharts";
import { isMobile } from 'react-device-detect';


export default function MoveStats(props) {

    function getGraphData(gameNumber){
      if(props.gameData.length <= 0){
        return false
      }
      if(props.gameData[gameNumber].moveAnalytics.length <=0){
        return false
      }
        var agents = []
        var stepData = []
        for(var i =0; i < props.gameData[gameNumber].moveAnalytics.moves.length; i++){
            if(!agents.includes(Object.keys(props.gameData[gameNumber].moveAnalytics.moves[i])[0])){
                agents.push(Object.keys(props.gameData[gameNumber].moveAnalytics.moves[i])[0])
            }
        }
        console.log("agents")
        console.log(agents)
        var stepCount = 0
        // loop through all moves
        for(var i =0; i < props.gameData[gameNumber].moveAnalytics.moves.length;){

            // group moves into each agents move so first move of the teams agent will be grouped into one group object
            var moveData = {}
            moveData['title'] = 'Move #' + String(stepCount) + " - #" +String(stepCount+1)
            for(var j =0; j < agents.length; j++){

                moveData['Agent ID: ' + String(agents[j])] = Object.values(props.gameData[gameNumber].moveAnalytics.moves[i])[0]
                // moveData['1'] = Object.values(props.gameData[0].moveAnalytics.moves[i])[0]
                i++
            }
            stepData.push(moveData)
            stepCount+=1
        }
        console.log(stepData)
        return stepData
       
    }



    function generateGraphData(){

      return(
      props.gameData.map((game, gameNumber) => {
          return generateGraph(gameNumber)
        })
      )

    }


    function generateGraph(gameNumber){
      if(props.gameData.length <= 0){
        return false
      }
        var agents = []
        for(var i =0; i < props.gameData[gameNumber].generateGraph.moves.length; i++){
            if(!agents.includes(Object.keys(props.gameData[gameNumber].moveAnalytics.moves[i])[0])){
                agents.push(Object.keys(props.gameData[gameNumber].moveAnalytics.moves[i])[0])
            }
        }

    return (
      <div className="divide-y-2 ">
        <div style={{ width: '100%', height: 200, minWidth: '52vmin'}}>
                               <dd className="mt-1 font-semibold text-gray-900 text-xxs md:text-xl my-auto">
                                 Game #{gameNumber}
                              </dd>
    <ResponsiveContainer width="100%" height={200}>
        <AreaChart
      syncId="anyId"
      data={getGraphData(gameNumber)}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 30,
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey="title" />
      <YAxis label={{ value: 'seconds', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      {agents.map((agentID, index) => {
        var agent2Stroke = "#F44F36"
        var agent2Fill = "#F44F36"

        var agent1Stroke = "#ff9805" 
        var agent1Fill = "#FABB88"

        
        var stroke = agent1Stroke
        var fill = agent1Fill
        if(index % 2 == 0){
          stroke = agent2Stroke
          fill = agent2Fill
        }
        return <Area
        type="monotone" 
        dataKey={"Agent ID: " + String(agentID)}
        stroke={stroke} 
        fill={fill}
        animationDuration="999"
        
      />
      }
        
    
  )}
      {/* <Area 
      type="monotone" 
      dataKey="Agent ID: 0" 
      stroke="#FF1700" 
      fill="#F93623" />

      <Area 
      type="monotone" 
      dataKey="Agent ID: 2" 
      stroke="#FF5D00" 
      fill="#F97225" /> */}
 
        <ReferenceLine y={props.gameData[gameNumber].moveAnalytics.moveTimeLimit} label="Max" stroke="red" />
        <ReferenceLine y={props.gameData[gameNumber].moveAnalytics.maxMoveSec.moveSec} label="Highest Move Time" stroke="red" />

        <Brush title="title" height={20} stroke="#8884d8"/>

    </AreaChart>
    </ResponsiveContainer>

    </div>
    {gameNumber < props.gameData.length-1 ? <div className="pt-2"></div> : null}
    
    </div>

  
    )
      }
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => props.setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Move Time Analytics
                  </Dialog.Title>
                  <div className="mt-2">
                    {/* {generateGraphData()} */}
                  </div>
                </div>
              </div>
              {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => props.setOpen(false)}
                >
                  Deactivate
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => props.setOpen(false)}
                >
                  Cancel
                </button>
              </div> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
