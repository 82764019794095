import logo from '../logo.svg';
import { useCookies } from 'react-cookie';

import '../App.css';

import Navigation from '../components/navigation'

import Hero from '../components/hero'
import HeroTail from '../components/heroTail'

import Section1 from '../components/sections/section1'
import Section2 from '../components/sections/section2'
import Section3 from '../components/sections/section3'
import MainPanel from '../components/sections/mainPanel'

import Test from '../components/sections/test'
import Test2 from '../components/sections/test2'

import Tailwind from '../components/sections/tailwind'
import TailwindTwo from '../components/sections/tailwintwo';
import FooterTop from '../components/FooterTop'
import FooterContent from '../components/footerContent'
import Footer from '../components/footer'

import AdSection from '../components/sections/ad'

import Faq from '../components/sections/faq';
import Dots from '../components/sections/dots';
const showAds = true;
var numGridCols = 1;
var contentCols = 1;
// Grid Size with / without ads
if(showAds){
	numGridCols = 5
	contentCols = 3
	
}

export default function Index() {
	const [cookies, setCookie] = useCookies(['user']);
	console.log(cookies.teamName)
	return (
		<div className="gradient">
		<div className="App lg:overflow-visible">

			{/* <Navigation /> */}

			<Hero />
			<HeroTail />

			<div class={"grid sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-5 "}>
				<AdSection shouldShowAds={showAds} adType="desktop"/>


				<div class={"bg-black sm:col-span-1 md:grid-cols-1 xl:col-span-3"}>
					<div class="App">
					{/* <div className="overflow-hidden">
						<Section1 />
					</div> */}
					{/* <Dots/> */}

						<Test />
						{/* <div class=""> */}
						{/* <Tailwind /> */}
						<AdSection shouldShowAds={showAds} adType="mobile"/>
						<div className="overflow-hidden lg:overflow-visible">
						<TailwindTwo />
						</div>

						
						<AdSection shouldShowAds={showAds} adType="mobile"/>
						<AdSection shouldShowAds={showAds} adType="mobile"/>
						<div className="overflow-hidden">
						<MainPanel />
						</div>
						<AdSection shouldShowAds={showAds} adType="mobile"/>
						<div className="overflow-hidden">

						<Faq />
						{/* <Section3 /> */}
						</div>

					</div>
				</div>

				<AdSection shouldShowAds={showAds} adType="desktop"/>

			</div>



			<FooterTop />
			{/* <FooterContent /> */}
			{/* <Footer /> */}

		</div>
		</div>

	);
}