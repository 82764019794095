import Identicon from 'react-identicons';
import { useCookies } from 'react-cookie';
import React, { useState, useEffect, Fragment, forceUpdate } from 'react';

/* This example requires Tailwind CSS v2.0+ */
function getHouseTeamWinRate(teamName){
    var min = 1;
    var max = 100;
    var rand = min + Math.random() * (max - min);
    var diff = 0;
    if(rand > 90){
        function genRand(min, max, decimalPlaces) {  
            var rand = Math.random()*(max-min) + min;
            var power = Math.pow(10, decimalPlaces);
            return Math.floor(rand*power) / power;
        }
        if(teamName === 'PacMan AI [Easy]'){
            return String(16.21 + genRand(0,2,2)) + '%'
        }else if(teamName === 'PacMan AI [Standard]'){
            return String(45.82 + genRand(0,2,2)) + '%'
        }else if(teamName === 'PacMan AI [Hard]'){
            return String(78.18 + genRand(0,2,2)) + '%'
        }
    }

    if(teamName === 'PacMan AI [Easy]'){
        return String(16.21) + '%'
    }else if(teamName === 'PacMan AI [Standard]'){
        return String(45.82) + '%'
    }else if(teamName === 'PacMan AI [Hard]'){
        return String(78.18) + '%'
    }

}



const houseTeams = [
    {
        teamName: 'Pacman AI [Easy]',
        email: 'lesliealexander@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      }, 
      {
        teamName: 'Pacman AI [Standard]',
        email: 'lesliealexander@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      }, 
      {
        teamName: 'Pacman AI [Hard]',
        email: 'lesliealexander@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },   
    // More people...
  ]
  
  export default function TeamSelectionList(props) {
    const [cookies, setCookie] = useCookies(['user']);
    const [createdTeam, setCreatedTeam] = useState(false);
    var selectedTeam = false


    function submitGame(e, opponantTeamName){
      if(selectedTeam){
        return
      }
      selectedTeam = true
      e.preventDefault();
    
      var currentTeamName;
      var currentTeamPass;
      if(props.gameType === "demo"){
        currentTeamName = "demo"
        currentTeamPass = "demo"
      }else{
        var currentTeamName = cookies.teamName
        var currentTeamPass = cookies.teamPass
      }
      const gameData = {"organization": String(props.org).toUpperCase(), "teamName": String(currentTeamName), "teamPassword": String(currentTeamPass), "redTeam": String(currentTeamName), "blueTeam": String(opponantTeamName), "randomSeed": String(props.randSeed), "map": String(props.map), "numberOfGames": String(props.rounds)};

      fetch("https://addJobToQueue.pacman.ai/", { 
        method: 'POST',
        headers:  {
          'Accept': 'application/json'
      },
        body: JSON.stringify(gameData)
    }).then((res) => res.json())
        .then((data) => {
          console.log("resp data")
         console.log(data);
          if(data.queueData.success && data.sessionID.length > 10){
            // State 1. see mainPanelContent.js for states.
              if(!createdTeam){
      
                setCreatedTeam(data.sessionID)
      
              }
          
            // setCookie('sessionID', String(data.sessionID), { path: '/' })
            // alert(JSON.stringify(data))
            
          }else{
            console.error("Error with creating game")
          }
    
        })
        .catch((error) => {
          console.log("error");
    
          console.log(error);
        });
    
    }
    useEffect(() => {
      if(String(createdTeam).length > 10 ){
  
      props.setGameState(1)
      props.setSessionID(createdTeam,
        function() { console.log("setSessionID State completed", createdTeam) })
      }
      }, [createdTeam]);
    return (

      <div className="py-3 grid grid-cols-1 gap-4 sm:grid-cols-3">
        {houseTeams.map((person) => ( 
          <div
            key={person.email}
            className="max-w-lg w-full sm:max-w-xs relative rounded-lg bg-white px-6 py-5 shadow-sm flex items-center space-x-3 border-gray-400 ring-2 ring-offset-2 ring-indigo-500"
            onClick={e => submitGame(e, person.teamName)}

          >
            <div className="flex-shrink-0">
              <Identicon className="h-10 w-10 rounded-md" string={person.teamName} size="400" count={5} bg="#ffffff"/>

            </div>
            <div className="flex-1 min-w-0">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{person.teamName}</p>
                <p className="text-sm text-gray-500 truncate">Win rate: {getHouseTeamWinRate(person.teamName)}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
    )
  }
  