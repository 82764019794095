
import React, { useState, useEffect, Fragment, forceUpdate } from 'react';

import UserTeamSelectionList from "./userTeamSelectionList"
export default function CreateGamePanel(props) {
  const [orgCode, setOrgCode] = useState("")
  const [randSeed, setRandSeed] = useState(false)
  const [mapNumber, setMapNumber] = useState("1337")
  const [numRounds, setNumRounds] = useState("3")
  

  function updateOrg(e){
    e.preventDefault();
    const orgName = e.target.value;

    setOrgCode(orgName)
  }

  function setRandomSeed(e){
    const randSeedChoice = e.target.value;
    if(randSeedChoice === 'yes'){
      setRandSeed(true)

    }else if(randSeedChoice === 'no'){
      setRandSeed(false)

    }

  }

  function setMapNumper(e){
    const mapNumberVal = e.target.value;
    setMapNumber(mapNumberVal)

  }

  function setNumOfRounds(e){
    const numRoundsVal = e.target.value;
    setNumRounds(numRoundsVal)
  }
    return (
      <form className="col-start-1 md:col-end-8 space-y-8 divide-y divide-gray-200 h-75 min-h-75 px-4 md:px-0">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Create Game</h3>
              <p className="mt-1 text-sm text-gray-500">Set up a game to test your AI</p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Organization Code
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="text-sm text-gray-500 text-left py-3">Used for privacy and security. <br/> This will put your team into a private queue - to be run on servers the organization owns.</p>

                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    defaultValue={props.gameType === "demo" ? 'DEMO' : null}
                    className="disabled max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={e => updateOrg(e)}
                  />
                </div>
                </div>
              </div>

              <div className="pt-6 sm:pt-5 ">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline sm:border-gray-200">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Use Random Seed
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <p className="text-sm text-gray-500 text-left py-3">Used when setting the Ranom.Seed() function.<br/> The default static-seed can be found in the 'capture.py' game file. </p>
                        <div 
                          className="mt-4 space-y-4"
                        >
                          <div className="flex items-center">
                            <input
                              id="no-random"
                              name="random-choice"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              defaultChecked='true'
                              value='no'
                              onClick={e => setRandomSeed(e)}

                            />
                            <label htmlFor="no-random" className="ml-3 block text-sm font-medium text-gray-700">
                              No
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="yes-random"
                              name="random-choice"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              value='yes'
                              onClick={e => setRandomSeed(e)}

                            />
                            <label htmlFor="yes-random" className="ml-3 block text-sm font-medium text-gray-700">
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="zip" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Map Number
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="text-sm text-gray-500 text-left py-3">Used to generate the game map</p>

                  <input
                    type="number"
                    name="map-number"
                    id="map-number"
                    autoComplete="pacmanAIMapNumber"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    defaultValue="1337"
                    onChange={e => setMapNumper(e)}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="zip" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Number Of Rounds
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="number"
                    min="1" 
                    max="5"
                    defaultValue="3"
                    name="num-rounds"
                    id="num-rounds"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={e => setNumOfRounds(e)}
                  />
                </div>
              </div>


            </div>
          </div>
  
          <div className="divide-y pt-8 space-y-6 sm:pt-10 sm:space-y-5">
           
            <div className="space-y-6 sm:space-y-5 divide-y">

                <UserTeamSelectionList map={mapNumber} org={orgCode} randSeed={randSeed} rounds={numRounds} setGameState={props.setGameState} setSessionID={props.setSessionID} 
                gameType={props.gameType}/>
            </div>
          </div>
        </div>
  
        <div className="pt-3">
        </div>
      </form>
    )
  }
  