import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { ExclamationIcon, XIcon, CheckIcon, S } from '@heroicons/react/outline'
import { useCookies } from 'react-cookie';
import queryString from 'query-string';

import RepoSelection from '../components/sections/createTeam/repoSelection';

import { Dialog, Transition } from '@headlessui/react'

import Identicon from 'react-identicons';

import ReactJsTyping from 'reactjs-typing-effect';


export default function CreateTeam(props) {
  const [teamValid, setTeamValid] = useState("none");
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerMsg, setErrorBannerMsg] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamCreated, setTeamCreated] = useState(false)
  const [teams, setTeams] = useState({})

  const [teamRepos, setTeamRepos] = useState([])
  const [teamToken, setTeamToken] = useState("")
  const [gitInstallID, setGitInstallID] = useState("")
  const [gitUsername, setGitUsername] = useState("")

  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const placeholderRepo = { id: 0, repoName: 'Select repo to use...' }
  const [selectedRepo, setSelectedRepo] = useState(placeholderRepo)

  const [fakeNames, setFakeNames] = useState(['Amazing Team Name'])
  const iconUpdateTime = 10000
  const fakeTextTypeTime = iconUpdateTime / 2
  const [time, setTime] = useState(Date.now());
  const [winFakeRate, setFakeWinRate] = useState(1.33);

  var teamID = ""
  var gitToken = ""
  var installID = ""
  const history = useHistory();


  function getTeams() {
    fetch("https://api.pacman.ai/getTeamNames")
      .then(response => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        setTeams(jsonData)
        console.log(jsonData)

      })
      .catch((error) => {
        // handle your errors here
        console.error(error)
      })
  }

  function getNames() {
    if (Object.keys(fakeNames).length <= 1) {

      fetch("https://api.pacman.ai/names/?amount=100")
        .then(response => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          setFakeNames(jsonData.name)
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    }
  }


  async function getTokenAndData(gitCode, installID) {
    fetch("https://api.pacman.ai/exchangeGitToken", {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: JSON.stringify({ "gitCode": gitCode, "gitInstallID": installID, "getGitData": "repos" })
    })
      .then(response => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        setGitInstallID(installID)
        if (jsonData.success) {
          if (teamToken === "") {
            setTeamToken(jsonData.tokenResponse.access_token)
            setCookie('teamToken', String(jsonData.tokenResponse.access_token), { path: '/' })

          }
          if (gitUsername === "") {
            setGitUsername(jsonData.gitUsername)
            setCookie('gitUsername', String(jsonData.gitUsername), { path: '/' })

          }
        } else {
          /* If success is false, meaning the code has already been exchanged
          * for a token then get the token and username from cookies.
          */
          if (cookies.teamToken) {
            setTeamToken(cookies.teamToken)
          }
          if (cookies.gitUsername) {
            setGitUsername(cookies.gitUsername)
          }
        }
        var repoList = []
        for (var i = 0; i < jsonData.userRepos.length; i++) {
          var repoToAdd = {
            id: i + 1,
            repoName: jsonData.userRepos[i].name
          }
          repoList.push(repoToAdd)
        }
        // Order repos in alphabetic order.
        repoList.sort(function (a, b) {
          if (a.repoName < b.repoName) { return -1; }
          if (a.repoName > b.repoName) { return 1; }
          return 0;
        })
        console.log(repoList)
        setTeamRepos(repoList)
        return jsonData.success;
      })
      .catch((error) => {
        // handle your errors here
        console.error(error)
        return false;
      })
  }



  function renderTeamBoxColor() {
    if (teamValid === "none") {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
    } else if (teamValid === "invalid") {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm focus:ring-red-500 focus:border-red-600"
    } else {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm focus:ring-green-500 focus:border-green-600"
    }
  }

  useEffect(() => {
    if (props.hasOwnProperty("location")) {
      const params = queryString.parse(props.location.search)
      if (params.setup_action === "install") {
        gitToken = params.code || ""
        installID = params.installation_id || ""
        teamID = params.state || ""

      }

    }
    // alert(JSON.stringify(state))
    // console.log(props)
    console.log("debug issues")
    console.log(gitToken)
    console.log(installID)
    var tokenResponse = "none"
    if (Object.keys(teamRepos).length == 0 || teamToken !== "") {
      tokenResponse = getTokenAndData(gitToken, installID)
    }


    if (Object.keys(teams).length == 0) {

      getTeams()
    }

    if (Object.keys(teams).length == 0 || Object.keys(teamRepos).length == 0 || teamToken == "") {
      console.log(" An Error occured please reinstall the GitHub App.")
    }

    if (Object.keys(fakeNames).length == 0) {
      getNames();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now())
      setFakeWinRate(Number(((Math.floor(Math.random() * 250) + 1) / 100).toFixed(2)))
    }, iconUpdateTime);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function fetchGitData() {
    // e.preventDefault();
    console.log("fetching git data")
    const params = queryString.parse(props.location.search)
    gitToken = params.code || ""
    installID = params.installation_id || ""
    teamID = params.state || ""

    if (Object.keys(teamRepos).length == 0 || teamToken !== "") {
      getTokenAndData(gitToken, installID)
    }


  }


  async function checkTeam(e) {
    var selectedName = await e.target.value
    validateTeamName(selectedName)
  }

  function validateTeamName(selectedName) {
    // Team names must not contin the following
    const mustNotContainRegex = /^[^~!*()']+$/
    if(!mustNotContainRegex.test(selectedName)){
      setTeamValid("invalid");
      setErrorBannerMsg("Looks like that name has a blacklisted symbol, try again.");
      setShowErrorBanner(true);
      return false
    }
    console.log("a")
    console.log(selectedName)
    console.log(teamToken)
    console.log(gitUsername)

    const TeamDatNotEmpty = Object.keys(teams).length > 0;
    if (TeamDatNotEmpty) {
      if (teams.teamData.hasOwnProperty(selectedName)) {
        console.log("invalid")
        setTeamValid("invalid");
        setErrorBannerMsg("Uh Oh! Someone else has already taken that team name.");
        setShowErrorBanner(true);
      } else if (selectedName.length > 20) {     // Invalid teamName
        setTeamValid("invalid");
        setErrorBannerMsg("Team name is too looooooooong! Team names must between 3 and 20 characters long.");
        setShowErrorBanner(true);

      } else if (selectedName.length > 2 && selectedName.length <= 20) {                                          // Valid teamName
        setTeamValid("valid");
        setShowErrorBanner(false);

      } else if (selectedName.length <= 2) {
        setTeamValid("none");
        setErrorBannerMsg("Names must be between 3 and 20 characters");
        setShowErrorBanner(false);
      }

    } else if (teamValid === "invalid" || teamValid === "none") {
      // Fetch teams again
      console.log(teamValid)
      // getTeams()
    }
    console.log(teamValid)

    setTeamName(selectedName);

  }

  function closeBanner() {
    if (showErrorBanner) {
      setShowErrorBanner(false);
    }
  }


  async function submitCreateTeam(e) {
    console.log(selectedRepo)
    e.preventDefault();
    if (selectedRepo.id === placeholderRepo.id || selectedRepo.repoName === placeholderRepo.repoName) {
      setErrorBannerMsg("Please select a repository to use.");
      setShowErrorBanner(true);
      return false
    }
    if (!teamValid === "valid") {
      setErrorBannerMsg("Something isn't right... Check the team name is valid and try again.");
      setShowErrorBanner(true);
      return false
    } else if (teamName.length <= 2) {
      setErrorBannerMsg("The team name needs to be more then 3 characters in length.");
      setShowErrorBanner(true);
      return false
    }

    // alert(gitUsername)
    await fetch("https://api.pacman.ai/signup", {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: JSON.stringify({ teamID: teamID, selectedName: teamName, gitToken: teamToken, gitInstallID: gitInstallID, gitRepoSelected: selectedRepo.repoName, gitUsername: gitUsername })
    }).then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setTeamValid("invalid");
          setShowErrorBanner(true);
          setErrorBannerMsg("Uh Oh! Someone else has already taken that team name.");
          getTeams()
        } else if (data.errorCode === 888){
          setTeamValid("invalid");
          setShowErrorBanner(true);
          setErrorBannerMsg("Not enough info provided, enter all data required.");
          getTeams()
        } else if (data.errorCode === 999){
          setTeamValid("invalid");
          setShowErrorBanner(true);
          setErrorBannerMsg("Team name does not adhere to the naming conventions. Please try another team name.");
          getTeams()
        } else {

          setCookie('teamName', teamName, { path: '/' })
          setCookie('teamPass', data.teamPass, { path: '/' })
          console.log("success")
          console.log(teamName)
          setTeamCreated(true)
          // Remove the cookies of this git sensitive data when team is successfully created.
          removeCookie('teamToken', { path: '/' });
          removeCookie('gitUsername', { path: '/' });
        }

      })
      .catch((error) => {
        console.log("error");

        console.log(error);
      });

  }

  function completeSignup(e) {
    e.preventDefault();

    history.push("/");
    window.location.reload();

  }

  function ShowSuccessModal() {
    return (
      <div class={teamCreated ? "fixed z-10 inset-0 overflow-y-auto" : "hidden"} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>


          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Team created successfully
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">

                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button onClick={(e) => completeSignup(e)} type="button" class="SignInBtn inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm">
                Go back to dashboard
              </button>
            </div>
          </div>
        </div>
      </div>

    )
  }
  function ErrorBanner() {
    return (
      <div className={showErrorBanner ? "fixed bottom-0 inset-x-0 pb-2 sm:pb-5" : "hidden"}>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-red-500 shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-red-500">
                  <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:inline">{errorBannerMsg}</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">

              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={(e) => closeBanner()}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (<div>
    {ShowSuccessModal()}
    <div class=" w-screen h-screen flex-none -ml-full bg-gray">

      <div className="focus:ring-green-500 focus:border-green-600"></div>
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-3xl shadow-2xl drop-shadow-2xl">
          <div className="sm:mx-auto sm:w-full sm:max-w-md ">

            <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">Create your team</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              The name {' '}
              <a className="text-lg font-medium text-red-500 hover:text-red-600">
                cannot
              </a>
              {' '} be changed later
            </p>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md overflow-hidden">
            <div className="bg-white py-8 px-4 sm:rounded-3xl sm:px-10 ">
              <form className="space-y-6">
                < RepoSelection teamRepos={teamRepos} setSelectedRepo={setSelectedRepo} selectedRepo={selectedRepo} fetchGitData={fetchGitData} />

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Team Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="teamName"
                      name="teamName"
                      type="text"
                      onChange={checkTeam.bind(this)}
                      required
                      className={renderTeamBoxColor()}
                    />
                  </div>
                </div>
                <div className="teamNameRules">
                  <li class={teamValid === "none" ? "flex items-center py-1" : "hidden"}>
                    <div className="rounded-full p-1 fill-current bg-red-200 text-red-700">
                      <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>

                      </svg>
                    </div>
                    <span class="font-medium text-sm ml-3 text-red-700">Names must be between 3 to 20 characters, and not contain ~ ! * ( ) '</span>
                  </li>
                </div>
                <div>
                  <div className="col-start-2 row-start-1 flex inline-flex py-3 has-tooltip space-x-1 ">
                    <label className="text-sm font-medium text-gray-400">
                      Team Preview
                    </label>
                    <span class='tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8 text-sm font-medium text-gray-700'>How others will see your team.</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="has-tooltip h-5 w-4 text-gray-400 " viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div
                    key="{person.email}"
                    className="w-full relative rounded-lg bg-white px-6 py-5 shadow-xl flex items-center space-x-3 border-gray-400 ring-2 ring-offset-2 ring-indigo-600"
                  >

                    <div className="flex-shrink-0">
                      <Identicon className="h-10 w-10 rounded-md" string={teamName.length > 2 ? teamName : time} size="400" count={5} bg="#ffffff" />

                    </div>
                    <div className="flex-1 min-w-0">
                      <a className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">
                          <div >
                            {getNames()}
                            {teamName.length > 2 ? teamName : <div className="capitalize"><ReactJsTyping StringList={fakeNames} speed={fakeTextTypeTime} /></div>}
                          </div>
                        </p>

                        <p className="text-sm text-gray-500 truncate">Win rate: {teamName.length > 2 ? "1.337" : winFakeRate}</p>
                      </a>
                    </div>
                  </div>
                </div>


                <div>
                  <button
                    className={teamValid === "valid" ? "SignInBtn w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2" : "bg-indigo-300 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                    disabled={teamValid === "valid" ? false : true}
                    onClick={(e) => submitCreateTeam(e)}
                  >

                    Look good? Let's go!
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
    {ErrorBanner()}
  </div>

  );

}