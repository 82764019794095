/* This example requires Tailwind CSS v2.0+ */
const people = [
    {
      name: 'PacMan AI [Hard]',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
    },
    // More people...
  ]
  const gameHistory = [
    { id: 1, team: people[0], score: '12/24', winLoss: 'won', gitHash: '2d89f0c8', timeAgo: '1h', numRounds: '3', sessionID: '11111' },
    { id: 2, team: people[0], score: '18/24', winLoss: 'won', gitHash: '2d89f0c8', timeAgo: '2h', numRounds: '1', sessionID: '22222' },
    { id: 3, team: people[0], score: '18/24', winLoss: 'won', gitHash: '2d89f0c8', timeAgo: '3h', numRounds: '5', sessionID: '33333' },
    { id: 4, team: people[0], score: '3/10', winLoss: 'lost', gitHash: '2d89f0c8', timeAgo: '1d', numRounds: '3', sessionID: '444444' },
    { id: 5, team: people[0], score: '18/24', winLoss: 'won', gitHash: '2d89f0c8', timeAgo: '1w', numRounds: '1', sessionID: '555555' },

    // More items...
  ]
  
  
  export default function GameHistory() {

    function getGameData(e, sessionID){
        e.preventDefault();
        // alert('TODO: fetch and show data for sessionID: ' + sessionID)

    }
    return (
      <div className="mx-3 md:col-start-3 md:col-end-6">
        <ul className="divide-y divide-gray-200">
          {gameHistory.map((game) => (
            <li key={game.id} className="cursor-pointer pl-1 sm:pl-0 py-4" onClick={(e) => getGameData(e, game.sessionID)}>
              <div className="flex space-x-3">
                <img className="h-6 w-6 rounded-full" src={game.team.imageUrl} alt="" />
                <div className="flex-1 space-y-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">{game.team.name}</h3>
                    <p className="text-sm text-gray-500">{game.timeAgo} ago</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {game.winLoss} | Score: {game.score} | Rounds: {game.numRounds} ({game.gitHash})
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
  