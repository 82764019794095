import React, { useState, useEffect, Fragment, forceUpdate, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import Identicon from 'react-identicons';
import { isMobile } from 'react-device-detect';

import { useCookies } from 'react-cookie';
import LoadPlaceholder from '../../../util/auth';
import ReactJsTyping from 'reactjs-typing-effect';
import { PaperClipIcon } from '@heroicons/react/solid'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, DownloadIcon} from '@heroicons/react/outline'
/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import Countdown from 'react-countdown';

import $ from 'jquery';

import Typist from 'react-typist';

import MoveStats from '../analytics/moveStats';
import PointAnalytics from '../analytics/pointAnalytics';
export default function GamePanel(props) {
    const [time, setTime] = useState(Date.now());
    const [sessionID, setSessionID] = useState(false);
    const [gameData, setGameData] = useState({});
    const [cookies, setCookie] = useCookies(['user']);
    const [openMoveStats, openMoveStatsOpen] = useState(false)
    const [terminalIsPaused, setTerminalIsPaused] = useState(false)


    function getTermCookie(){
        return cookies.terminals;
    }
    useEffect(() => {


        const interval = setInterval(() => {
            console.log("aaaaaaaaa")
            try{
                console.log(gameData)
                console.log(gameData.data)
                console.log(gameData.data.data)
                // alert(gameData.data.data.completed)
                if(gameData.data.data.completed != true){
                    console.log("bbbbbbbbbb")
                    getGameData()
                }else{
                    console.log("dddddddd")
                    return
                }
            }catch(err){
                console.log("ffffffff")
                console.log(err)
                getGameData()
            }

            // setTime(Date.now())

        }, 10000);

        try{
            if(gameData.data.data.completed == true){

            clearInterval(interval);
            return
            }
        }catch(err){

        }
        return () => {

            clearInterval(interval);


        };


    }, [gameData]);


    function getGameData() {
        try{
            // alert(gameData.data.data.completed )
            if(gameData.data.data.completed == true){
                return
            }
        }catch(err){

        }
        fetch(`https://job.pacman.ai/?sessionID=${props.sessionID}&serverID=client`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'msgID': 2

            }
        })
            .then(response => response.json())
            .then((jsonData) => {
                var dataToBeHandled
                if(jsonData.service === "MB"){
                    dataToBeHandled = jsonData
                }else if(jsonData.service === "KV"){
                    dataToBeHandled = {
                        'success': true,
                        'data': jsonData,
                    }
                    dataToBeHandled.data.success = true
                }
                processJobData(dataToBeHandled)
            })
    }

    function processJobData(jsonData){
        console.log(jsonData)
        if (jsonData.data.success || jsonData.data.success === "True") {
            // The way i have constructed this JSON object is a sin... never do this again.
            if (jsonData.data.hasOwnProperty('msgID') || jsonData.data.service == "KV"){
                if(!cookies.terminals){
                    var cookieGameData = []
                    console.log(jsonData)
                    // alert(JSON.stringify(gameData))
                    for(var i = 0; i < jsonData.data.data.data.gameData[0].numberOfGames; i++){
                        cookieGameData.push({
                            hasBeenOpened: false,
                            finishedPrinting: false,
                            showNext: false,
                        })
                    }
                    const terminalData = {updateCount:0, terminalData: cookieGameData}
                    setCookie('terminals', terminalData, { path: '/' })
                }
               
                // alert(JSON.stringify(jsonData))
                // alert(JSON.stringify(gameData))
                if (Object.keys(gameData).length === 0) {
                  
                    setGameData(jsonData,
                        function () { 
                            console.log("setState completed") 
                    
                        });


                } else if (jsonData.data.msgID >= 10 && gameData.data.msgID !== jsonData.data.msgID) {
                   
                    setGameData(jsonData,
                        function () { console.log("setState completed") });

                } else if (jsonData.data.data.completed) {
                    setGameData(jsonData,
                        function () { console.log("setState completed") });
                    
                }
            }
        }
    }





    function statisticsRow() {
        /* This example requires Tailwind CSS v2.0+ */
        const stats = [
            { name: 'Branch', stat: '58.16%' },
            { name: 'Commit', stat: '71,897' },
            { name: 'Game time', stat: '24.57%' },
            { name: 'Round #', stat: '24.57%' },
            { name: 'Points', stat: '24.57%' },
            { name: 'Avg move', stat: '24.57%' },
            { name: 'Num fails', stat: '24.57%' },
        ]
        console.log(gameData)
        console.log("gamemetadata")
        try{
            console.log(JSON.stringify(gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)].commit))
            console.log("----")
            console.log(String(gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)])['commit'])
        }catch(err){
            console.log("error")
            console.log(err)
        }
        function millisToMinutesAndSeconds(millis) {
            var minutes = Math.floor(millis / 60000);
            var seconds = ((millis % 60000) / 1000).toFixed(0);
            return (
                seconds == 60 ?
                (minutes+1) + ".00" :
                minutes + "." + (seconds < 10 ? "0" : "") + seconds
              );
          }
        function calcPoints(){
            var totalPoints = 0
            for(var i =0; i < gameData.data.data.data.gameResults.length; i++){
                totalPoints+=gameData.data.data.data.gameResults[i].gameScore;
            }
            return totalPoints
        }
          
        return (
            <div className="center">
                {gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.gameResults.length < 1 ? showGameInfoBanner() : null}

                {/* <h3 className="text-lg leading-2 font-medium text-gray-900">Stats</h3> */}
                <dl className="mt-2 grid gap-2 grid-cols-3 md:grid-cols-7">
                    {/* //   STATS Branch */}
                    <div key='branch' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2">
                        <dt className="text-sm font-medium text-gray-500 truncate">Branch</dt>
                        <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl my-auto">
                        {props.gameType === "demo" ? 'demo' :
                        gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)].branch
                    ? String(gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)].branch) : '--'}
                        </dd>
                    </div>

                    {/* //   STATS Commit */}
                    <div key='commit' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2">
                        <dt className="text-sm font-medium text-gray-500 truncate">Commit</dt>
                        <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto">
                            
                        {props.gameType === "demo" ? 'demo' : 
                        gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)].branch
                     ? String(gameData.data.data.data.codeData[encodeURIComponent(cookies.teamName)].commit).substring(0, 5) : '--'}
                    </dd>

                    </div>

                    {/* //   STATS Game time */}
                    <div key='game_time' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2">
                        <dt className="text-sm font-medium text-gray-500 truncate">Time</dt>
                        <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto">
                            
                        {gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.metaData.gameStartTime &&
                    gameData.data.data.data.metaData.gameEndTime
                    
                     ? millisToMinutesAndSeconds(gameData.data.data.data.metaData.gameEndTime - gameData.data.data.data.metaData.gameStartTime) : '--'}
                        <dd className="text-xl text-black text-xs">mins</dd>

                    </dd>
                    </div>
                    {/* //   STATS Round # */}
                    <div key='round_num' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2">
                        <dt className="text-sm font-medium text-gray-500 truncate">Round #</dt>
                        <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto">
                            
                        {gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.gameResults
                     ? gameData.data.data.data.gameResults.length : '--'}
                    </dd>
                    </div>
                    {/* //   STATS Points */}
                    <div key='points' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2">
                        <dt className="text-sm font-medium text-gray-500 truncate">Points</dt>
                        <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto">
                            
                            {gameData && 
                        gameData.data && 
                        gameData.data.data && 
                        gameData.data.data.data && 
                        gameData.data.data.data.gameResults
                         ? calcPoints() : '--'}
                        </dd>

                    </div>
                    {/* //   STATS Avg move */}
                    <div onClick={() => (!isMobile ? openMoveStatsOpen(true) : null)} key='avg_move' className={isMobile ? "px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2 " : "px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2 cursor-pointer"}>
                        <dt className="text-sm font-semibold text-gray-500 truncate">Avg move</dt>
                            <dd className="mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto">
                                {gameData && 
                                gameData.data && 
                                gameData.data.data && 
                                gameData.data.data.data && 
                                gameData.data.data.data.gameResults
                                ? !isNaN(getAvgMoveMs(gameData.data.data.data.gameResults))? 
                                getAvgMoveMs(gameData.data.data.data.gameResults) : '--' : '--'}
                            </dd>
                            <div className="static ">
                            {!isMobile ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="float-right h-5 w-4 text-gray-400 " viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                </svg>
                            : null}
                                <dd className={isMobile ? "text-xl text-black text-xs" : "text-xl text-black text-xs pl-3"}>secs</dd>
                            </div> 
                            
                       
                            {/* </div> */}


                    </div>
                    {/* //   STATS Num fails */}
                    <div key='num_fails' className="px-1 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-2 col-span-3 md:col-span-1">
                        <dt className="text-sm font-medium text-gray-500 truncate">Num fails</dt>

                        {gameData && 
                        gameData.data && 
                        gameData.data.data && 
                        gameData.data.data.data && 
                        gameData.data.data.data.gameResults
                         ? getNumFails(gameData.data.data.data.gameResults) : '--'}

                    </div>
                </dl>
            </div>
        )
    }

const steps = [
  { name: 'Step 1', href: '#', status: 'complete' },
  { name: 'Step 2', href: '#', status: 'complete' },
  { name: 'Step 3', href: '#', status: 'current' },
  { name: 'Step 4', href: '#', status: 'upcoming' },
  { name: 'Step 5', href: '#', status: 'upcoming' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getAvgMoveMs(gameResults){
    var totalMoves = 0;
    var totalTime = 0;
    for(var i =0;i <gameResults.length; i++){
        totalMoves+= gameResults[i].moveAnalytics.moveCount;
        totalTime+= gameResults[i].moveAnalytics.totalSecCount;
    }

    return (totalTime/totalMoves).toFixed(4)
}

function getNumFails(gameResults){
    var numFails = 0;
    for(var i =0;i <gameResults.length; i++){
        numFails+= gameResults[i].moveAnalytics.fails;
    }
    var textColor = "text-red-400"
    if(numFails<=0){
        textColor = "text-green-400"
    }



    return <dd className={'mt-1 font-semibold text-gray-900 text-xs md:text-xl overflow-ellipsis my-auto ' + textColor}>
            {numFails}
            </dd>
}

function getGamesForProgressBar(){

    if(gameData.data.data.data.gameData[0].numberOfGames){
        var games = [];
        for(var i =0; i < gameData.data.data.data.gameData[0].numberOfGames; i++){

            games.push({
                gameScore: i < gameData.data.data.data.gameResults.length &&
                gameData.data.data.data.gameResults[i].gameScore ? 
                gameData.data.data.data.gameResults[i].gameScore : null
            })
        }
        return games
    }else{
        return [{
            gameScore: 10
        }]
    }
}
function gameProgressBar() {
  return (
    <nav aria-label="Progress">
      <ol className="center flex items-center">
      {getGamesForProgressBar().map((game, stepIdx) => (
          <li key={String("gameNum") + String(game.roundNumber)} className={classNames(stepIdx !== gameData.data.data.data.gameResults.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
            {/* {game.gameScore > 0 ?} */}
            {/* If team won */}
            {game.gameScore !== null && game.gameScore > 0 ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-indigo-600" />
                </div>
                <a
                  href="#"
                  className="relative w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900"
                >
                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  <span className="sr-only">{String("gameNum") + String(game.roundNumber)}</span>
                </a>
              </>
            //   If team loses
            ) : game.gameScore !== null && game.gameScore < 0 ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href="#"
                  className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
                  <span className="sr-only">{String("gameNum") + String(game.roundNumber)}</span>
                </a>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href="#"
                  className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{String("gameNum") + String(game.roundNumber)}</span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }






    function mainBody() {

        return (
            // <h1>game data</h1>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {gameData.data.data.data.gameResults.map((game) => (
                    <Disclosure as="div" key={String(gameData.data.data.sessionID) + String(game.roundNumber)} className="pt-6">
                        {({ open }) => (
                            <>
                                <dt className="text-lg">
                                    <Disclosure.Button id={"game_tab" + String(game.roundNumber)} className="bg-gray-100 p-3 rounded-xl text-left w-full flex justify-between items-start text-gray-400">
                                    <span className={game.gameScore < 0 ? 'font-medium text-red-500 text-xl overflow-ellipsis font-bold m-auto' : 'font-medium text-green-400 text-xl overflow-ellipsis font-bold m-auto'}>
                                        {game.gameScore < 0 ? ' loss' : ' win'}
                                        </span>
                                        <div className="mr-auto">
                                        {!isMobile ? <>
                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto mx-1 overflow-ellipsis">Game: </span>
                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto mr-3 overflow-ellipsis"> {String(game.roundNumber+1)}</span>
                                        </>: null}
                                       
                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto mx-1 overflow-ellipsis">Map: </span>
                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto md:mr-3 overflow-ellipsis"> {String(game.mapNumber)} </span>

                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto mx-1 overflow-ellipsis">Score: </span>
                                        <span className="font-medium text-gray-900 text-xs md:text-md my-auto md:mr-3 overflow-ellipsis"> {String(game.gameScore)}</span>
                                        </div>
                                        
                                        {/*game.gameScore < 0: winner = 'Blue' */}

                                        <span className="ml-6 h-7 flex items-center">
                                        {/* <span className="ml-6 h-7 w-full flex items-right">
                                            {!isMobile &&
                                            gameData && 
                                            gameData.data &&
                                            gameData.data.data &&
                                            gameData.data.data.completed ? showDownloads(game, open) : null}

                                        </span> */}
                                            {<ChevronDownIcon
                                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                aria-hidden="true"
                                            />}
                                        </span>
                                    </Disclosure.Button>
                                </dt>
                                <Disclosure.Panel as="dd" className="mt-2 pr-2">
                                <span className="flex justify-center mb-4">
                                        <span className="ml-6 h-7">
                                            {gameData && 
                                            gameData.data &&
                                            gameData.data.data 
                                            ? showDownloads(game, open) : null}

                                        </span>
                                </span>


                                    <div className="text-base text-gray-500 text-left ">{typeWrite(game.gameLog, game.roundNumber)}</div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </dl>
        )
    }

    return (
        <div className="max-w-2xl w-full mx-auto col-span-full">
            {gameData && 
            gameData.data && 
            gameData.data.data && 
            gameData.data.data.data && 
            gameData.data.data.data.gameResults[0] &&
            gameData.data.data.data.gameResults[0].moveAnalytics? 
                <MoveStats gameData={gameData.data.data.data.gameResults} setOpen={openMoveStatsOpen} open={openMoveStats}/>
                : null}

            <div className="bg-white overflow-hidden rounded-lg divide-y divide-gray-200">
                <div className="px-4 py-2 sm:px-6">
                    {statisticsRow()}

                    {false && gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.gameData[0].numberOfGames 
                    ? gameProgressBar() : null}
                </div>
                <div className="px-4 px-6">
                    {gameData && 
                    gameData.data && 
                    gameData.data.data && 
                    gameData.data.data.data && 
                    gameData.data.data.data.gameResults && 
                    Object.keys(gameData).length >0 && 
                    Object.keys(gameData.data).length >0 &&
                    Object.keys(gameData.data.data).length >0 &&
                    Object.keys(gameData.data.data.data).length >0 &&
                    Object.keys(gameData.data.data.data.gameResults).length > 0 ? mainBody() : "waiting for runner..."}
                </div>
                        
            </div>
        </div>

    );

    

    function showDownloads(game, open){
        function downloadLogs(e, game, open) {
            open = !open
            e.preventDefault();

            var a = document.createElement("a"); //Create <a>
            a.href = "data:file/png;base64," + game.gameLog; //Image Base64 Goes here
            a.download = "game.log"; //File name Here
            a.click(); //Downloaded file
          }
        function downloadReplay(e, game, open) {
            open = !open

            e.preventDefault();

            var a = document.createElement("a"); //Create <a>
            a.href = "data:zip/png;base64," + game.gameReplay; //Image Base64 Goes here
            a.download = "replay.zip"; //File name Here
            a.click(); //Downloaded file
          }
        return(
                <div className="px-3 w-96">
                    <button
                    type="button"
                    className="mx-1 inline-flex items-center px-1 md:px-4 py-2 border shadow-sm text-sm font-sm md:font-medium rounded-md text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={e => downloadReplay(e, game, open)}

                >
                    <DownloadIcon className="-ml-1 mr-3 h-4 w-4" aria-hidden="true" />
                    Replay.zip
                </button>
                    <button
                    id={"downlaodGameLogs"+String(game.roundNumber)}
                    // href={'data:text/plain;base64,' + game.gameLog}
                    type="button"
                    className="mx-1 inline-flex items-center px-1 md:px-4 py-2 border shadow-sm text-sm font-sm md:font-medium rounded-md text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    // download='log.zip'
                    onClick={e => downloadLogs(e, game, open)}
                >
                    <DownloadIcon className="-ml-1 mr-3 h-4 w-4" aria-hidden="true" />
                    Game.log
                </button>
            </div>
        );
    }






function renderLogLines(base64encodedTest){
    let base64ToString = Buffer.from(base64encodedTest, 'base64').toString();
    base64ToString = base64ToString.split('\n');
    var logMap = []
    console.log(base64ToString[0])
    console.log("base64ToString.length")
    console.log(base64ToString.length)
    for(var i =12; i < base64ToString.length; i++){
        logMap.push({
            lineNumber: i-12,
            line: String(base64ToString[i]),
        })
        // console.log("000000")
        // console.log(base64ToString[i])

    }
    return logMap

}

function typeWrite(base64EncodedLog, roundNum){
    return (
        <>
    <div className="relative w-full absolute">
        
        <div className="relative md:rounded-xl shadow-2xl flex bg-rose-500 ">
            <div className="rounded-xl absolute inset-0 bg-black bg-opacity-75" /><div className="relative w-full flex flex-col">
      {/* // THE TOP OF THE WINDOW           */}
      {/* <div className="bg-black bg-opacity-25 flex-none h-11 flex items-center px-4"> */}
      {/* <div className="flex py-3">
        <div className="w-3 h-3 mr-2 bg-red-500 rounded-full" />
        <div className="w-3 h-3 mr-2 bg-yellow-500 rounded-full" />
        <div className="w-3 h-3 bg-green-500 rounded-full" />
        </div>
        <div class="pl-4 text-white">myTeam.py</div>

        </div> */}
        <div className="rounded-xl relative border-t border-white border-opacity-10 min-h-0 flex-auto flex flex-col">
            <div className="rounded-xl hidden md:block absolute inset-y-0 left-0 bg-black bg-opacity-50" style={{ width: '50px' }} /><div className="w-full flex-auto flex min-h-0"><div className="w-full relative flex-auto"><pre className="flex text-xs md:text-sm">
      <div aria-hidden="true" className=" rounded-xl z-10 hidden md:block text-white flex-none py-4 pr-1 text-right select-none h-10 rounded-xl">
      {/* <Typist
            className="TypistExample-num"
            cursor={{ hideWhenDone: true }}
            avgTypingDelay={10}
            stdTypingDelay={10}
        >
      {renderLogLines(base64EncodedLog).map((log) => (
          <div>
            <Typist.Delay ms={50} />
            {String(log.lineNumber) +"\n"}
          </div>

      ))}


        </Typist> */}

        </div>
        <div onClick={() => pauseTerminalOutput()} id={'main_term' + String(roundNum)} className={"term"+ String(roundNum) + " flex-auto bottom-0 block text-white pt-4 pb-4 px-3 text-left bottom-0 h-10 rounded-xl"}>
        <pre>
            <output id={"term_output"+ String(roundNum)}>


            </output>
        </pre>
        {terminalJQuery(roundNum, renderLogLines(base64EncodedLog), terminalIsPaused)}
        

        </div>
        </pre></div></div></div></div></div></div>

        </>
    );
}

function pauseTerminalOutput(){

    setTerminalIsPaused(!terminalIsPaused)
}

function terminalJQuery(divID, logLines, terminalPaused){


    const outputs = logLines;

    try{
        var finished = false;
        var inc = 0;
        var main_term = $('#main_term'+String(divID));
        var term = $('.term'+String(divID));
        var term_output =  $('#term_output'+String(divID));
        // hello
        var test = ""
        if (main_term[0] && term && term_output) {
            const hasTermBeenViewed = cookies.terminals.terminalData[divID].hasBeenOpened;
            var prevHasTermBeenViewed;
            var showNext;
            try{
                prevHasTermBeenViewed = cookies.terminals.terminalData[divID-1].hasBeenOpened;
                showNext = cookies.terminals.terminalData[divID-1].showNext;
            }catch(err){
                
               
            }
            if(divID === 0){
                prevHasTermBeenViewed = true
                showNext = true;
            }
            if(!hasTermBeenViewed && prevHasTermBeenViewed && showNext){
                var tmpCookies = cookies.terminals;
                tmpCookies.terminalData[divID].hasBeenOpened = true
    
                runTerminal(terminalPaused);
            }else if(showNext){
                
                showFullTerm(logLines)
                
  
            }else{

            }
            if(divID !== 0 && !showNext){
                $('#term_output'+String(divID)).text(String("     Waiting for game #" + String(divID) + " to finish...").toString());
            }


          }
        // }
        async function showFullTerm(logLines){
            const sleep = ms => {
                return new Promise(resolve => setTimeout(resolve, ms))
              }
            await sleep(1000)
            if($('#term_output'+String(divID)).text().length < 10){
                
                var showTerm = ""
                for(var i = 0; i < logLines.length; i++){
                    var tmpLine = String(logLines[i].line).replace(/\\n/g, "\\n")
                    .replace(/\\'/g, "\\'")
                    .replace(/\\"/g, '\\"')
                    .replace(/\\&/g, "\\&")
                    .replace(/\\r/g, "\\r")
                    .replace(/\\t/g, "\\t")
                    .replace(/\\b/g, "\\b")
                    .replace(/\\f/g, "\\f").toString() 
                    showTerm += String(logLines[i].lineNumber) + "    " +tmpLine + '\n'
                }
                $('#term_output'+String(divID)).text(showTerm);
            }
        }

        async function runTerminal(terminalPaused) {
            var rand = Math.round(Math.random() * (30 - 10)) + 1;
            for(var i = 0; i < logLines.length;){

            // await new Promise(resolve => {

                // setTimeout(function() {

                    // if (outputs[inc] == '#') ++inc;
                    const sleep = ms => {
                        return new Promise(resolve => setTimeout(resolve, ms))
                      }
                    await sleep(0.1)

                    console.log('term is paused')
                    console.log(terminalIsPaused)
                    printToTerminal(outputs[inc]);
                    ++inc;
                    
                    // finished = (inc >= outputs.length-1);

                    if (String(outputs[inc-1].line).includes('Total Time Game:')) terminalFinished();

            i++
        }
        };
        function terminalFinished() {
            setTimeout(function() {
            }, 500);
            setTimeout(function() {
                // printToTerminal(String('Cleaning up...').toString() );
            }, 700);
            setTimeout(function() {
                // printToTerminal(String('Zipping data...').toString() );
            }, 1500);
            setTimeout(function() {
                transitionForComplete();
            }, 2500);
            tmpCookies.terminalData[divID].showNext = true

        }
        function transitionForComplete() {
            var main_term = $('#main_term'+String(divID));
            var term = $('.term'+String(divID));
            var term_output =  $('#term_output'+String(divID));
            
            if (main_term[0] && term && term_output) {
                $('.term'+String(divID)).animate({'min-height':'200px','height':'200px'},{duration: 250,complete: function () {
                    $('#main_term'+String(divID)).scrollTop($('#main_term'+String(divID))[0].scrollHeight - $('#main_term'+String(divID))[0].clientHeight);
                    setTimeout(function() {
                    }, 500);
                }});
                $('.revealContent').slideDown();
            }
        }
        function printToTerminal(msg) {
            var main_term = $('#main_term'+String(divID));
            var term = $('.term'+String(divID));
            var term_output =  $('#term_output'+String(divID));

            if (main_term[0] && term && term_output) {


                var tmpLine = String(msg.line).replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f").toString() 
                

                $('#main_term'+String(divID)).scrollTop($('#main_term'+String(divID))[0].scrollHeight - $('#main_term'+String(divID))[0].clientHeight);
                $('#term_output'+String(divID)).append(String(msg.lineNumber) + "    "+tmpLine + '\n');
                
            }
        }
    }catch(err){
        console.log("ERROR")
        console.log(err)
    }
    
}

function showGameInfoBanner(){
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return 'Starting please wait.';
      } else {
        // Render a countdown
        if(hours){
          return <span>{hours}:{minutes}:{seconds}</span>;
  
        }else{
          return <span>{minutes}:{seconds}</span>;
  
        }
      }
    };

    return (
      <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-40">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-indigo-800">
                  <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                
                  <span className="inline md:hidden">Please be patient. Est wait: 
                    <Countdown
                    intervalDelay={1}
                    zeroPadTime={1}
                    date={Date.now() + (350000)
                    }
                    renderer={renderer}
                  />
                  </span>
                  {/* {alert(queueData.queueData)} */}
                  <span className="hidden md:inline">Please be patient, Setting up environment. Est time: 
                  <Countdown
                  intervalDelay={1}
                  zeroPadTime={1}
                  date={Date.now() + (350000)
                  }
                  renderer={renderer}
                />
               </span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                {/* <a
                  href="#"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                >
                  Learn more
                </a> */}
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}