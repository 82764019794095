import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

   /* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

export async function leaveQueue(sessionID, setGameState, gameState){
if(gameState === 99){
  console.log("fetching queue")
  console.log(sessionID)
  return fetch("https://api.pacman.ai/leaveQueue/", { 
     method: 'GET',
     headers:  {
       'Accept': 'application/json',
       'sessionID': sessionID || "hello"
   },
 }).then((res) => res.json())
     .then((data) => {
       console.log("resp data")
      console.log(data);
       if(data.success){
         // State 1. see mainPanelContent.js for states.
         setGameState(-1)

       }

     })
     .catch((error) => {
       console.log("error");

       console.log(error);
     });
}
    

}

