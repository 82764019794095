/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "What is Pacman.ai?",
    answer:
      "Pacman.ai is a platform for people to learn Artificial Intelligence and compete with others, to improve your AI. ",
  },
  {
    question: "Who uses Pacman.ai?",
    answer:
      "Anyone who is interested in learning or wants to improve their AI understanding. ",
  },
  {
    question: "What are 'Runners'",
    answer:
      "Runners are isolated instances of the Pacman.ai containers that run each teams code. Note that each runner has ~1Ghz vCPU and ~200MB RAM dedicated to each instance.",
  },
  {
    question: "What if i cant add the Pacman AI GitHub bot to my repo?",
    answer:
      "Either wait for your organization admin to add the bot or simply create a new repo, and either push your code to that repo manually or add a the new repo as a remote URl, so that each time you push code to your primary repo, the secondary repo will also get updated. ",
  },
  {
    question: "Can i change my team name or password?",
    answer:
      "At this moment, no. This may change in the future",
  },
  {
    question: "Can i remove the Pacman.ai bot from my account?",
    answer:
      "Yes of course, however doing so will also delete your team.",
  },
  {
    question: "What data is shared to Pacman.ai?",
    answer:
      "Nothing but read access to your repository, repo metadata, repos you have allowed the bot to access and your github public username. This may change, however you will be notified when signing up or GitHub will email you with the changes, to which you must approve. You are in total control, I don't ask for any more data then I need, and do not sell or share any data.",
  },
  {
    question: "Why is my game taking so long to run or complete?",
    answer:
      "This is a free service, running massively complex computational code, so I can only afford so many servers. The maximum time per game is 10 minutes... so you do the maths. If it is not running at all after waiting a decent amount of time, please submit an issue on the GitHub repo. ",
  },
  {
    question: "Why doesn't this site look good?",
    answer:
      "I work on Mainframes (yes these things really still exist) for a living, and do projects like this in my spare time so go easy...",
  },
  {
    question: "When will X feature be released?",
    answer:
      "When I have time :) ",
  },
  // More questions... https://github.com/JamesWRC/pacman-ai.git
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Faq() {
  return (
    <div className="backgroundGradient" >
      <a name="faq"></a>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" key='gettingStarted' className="pt-6">
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">How do I start?</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(true ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">Head over to <u><a href='https://github.com/JamesWRC/pacman-ai.git' className='text-black text-lg font-bold'>the official repo</a></u> and make a private fork of the repo, read the README and this FAQ, <u><a href='https://pacman.ai/login#login' className='text-black text-lg font-bold'> sign up </a></u> then have fun!</p>
                    </Disclosure.Panel>
                  </>
                
              </Disclosure>
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
