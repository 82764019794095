import React from 'react';
import { useHistory } from 'react-router-dom';
function Hero() {

  const history = useHistory();

  return (
    <div class="h-screen overflow-hidden relative">
              {/* <!-- Start Hero --> */}
              <div class="h-96 h-full container mx-auto px-5">
                <div class="xl:h-full grid gap-y-8 sm:gap-y-0 sm:gap-x-10 sm:grid-cols-2 mt-32 xl:mt-auto content-center">
                  <div class="sm:col-span-1 space-y-5 md:space-y-8">
                    <h1 class="text-4xl sm:text-5xl xl:text-7xl font-semibold text-white">Pacman.ai</h1>
                    <p class="text-s md:text-2xl">
                    First competitive AI Capture-The-Flag
                    </p>
                    <div class="flex justify-center space-x-8 lg:py-12">
                    <a className="z-20" href="login#login">
            <button type="button" class="inline-flex items-center px-6 py-3 border border-black shadow-sm text-base font-medium rounded-md text-black bg-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              &nbsp;&nbsp;Sign In&nbsp;&nbsp;
            </button>
          </a>
          <a className="z-20" href="demo#demo">

            <button type="button" class="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-100 bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              &nbsp;&nbsp;Demo&nbsp;&nbsp;
            </button>
          </a>

                    </div>
                  </div>
                  <div class="grid justify-items-center inline-flex items-center">
                    <img class="sm:absolute sm:-right-4 md:-right-8 lg:right-0 xl:right-0 bottom-0 w-3/3 sm:w-2/4 lg:w-5/12 xl:w-6/12 z-10" src="/img/phones5.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="hidden sm:block sm:absolute bottom-6 sm:inset-x-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                </svg>
              </div>
            </div>
  );


}

export default Hero;