import React, { useState, useEffect } from 'react'
import { BrowserRouter as Switch, Route, Link } from "react-router-dom"
import LoadingPlaceholder, { authenticateTeam } from '../../util/auth';
import { useCookies } from 'react-cookie';

import MainPanelTabs from './mainPanelTabs'

import LoginSignUp from './loginSignup'

// import Demo from './demo'

import MainPanelContent from './mainPanelContent';

const loginSignUpRoutes = ["", "/login", "/signup", "/", "/#", "/faq"]
const mainPanelContentRoutes = ["/play"]
const demoRoutes = ["/demo", "#demo"]

function  MainPanel() {
  const [cookies, setCookie] = useCookies(['user']);
  const [teamAuth, setTeamAuth] = useState();

  useEffect(() => {
    // Update the document title using the browser API
    authenticateTeam(cookies.teamName, cookies.teamPass).then((data) => {
      setTeamAuth(data.authenticated)
    })
  },[]);


  function showMainPanelContent(){
    return "a"
  }


  // const [registerBtnText, setRegisterBtnText] = useState(false);
  // setAuthentication(teamAuthed)

  var MainPanelToDisplay;
  var mainPanelToDisplayRoutes;

  if (teamAuth) {
    mainPanelToDisplayRoutes = loginSignUpRoutes.concat(mainPanelContentRoutes);
    MainPanelToDisplay = showMainPanelContent()
  } else {
    mainPanelToDisplayRoutes = loginSignUpRoutes;
    MainPanelToDisplay = LoginSignUp
  }

  if(teamAuth === undefined){
    mainPanelToDisplayRoutes = loginSignUpRoutes.concat(mainPanelContentRoutes);
    MainPanelToDisplay = LoadingPlaceholder
  }

  return (
    
    <div class="p-8 sm:py-4 overflow-hidden backgroundGradient">
      <div className="bg-white overflow-hidden rounded-3xl shadow-lg">
        <div className="py-3">
          <header className={teamAuth ? "hidden" : ""}>
            {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-left  text-gray-900">Dashboard</h1>
          </div> */}
            <div class="">
              <Switch>

                <Route path="/*" component={MainPanelTabs} />
              </Switch>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <Switch>

                {/* // if url contains demo then show demo, else if teamAuthed show normal game, else show signin / signup */}
                {(window.location.href.indexOf("demo") > -1) ? 
                <MainPanelContent gameType={"demo"} /> : 
                teamAuth ? <MainPanelContent gameType={"normal"} /> : 
                <Route exact path={mainPanelToDisplayRoutes} component={MainPanelToDisplay} /> }
                {/* <Route exact path={demoRoutes} component={Demo} /> */}
              </Switch>
              {/* <MainPanelRouter /> */}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default MainPanel;