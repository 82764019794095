import { UserIcon, PlayIcon, ArrowCircleUpIcon } from '@heroicons/react/solid'
import { useCookies } from 'react-cookie';

const loginSignUpRoutes = ["", "/login", "/signup", "/", "/#"]
const demoRoutes = ["/demo", "#demo"]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MainPanelTabs(props) {
  
  var loginFocused = false;
  var demoFocused = false
  const [cookies, setCookie] = useCookies(['user']);

  if(loginSignUpRoutes.indexOf(String(props.location.pathname)) > -1) {
    loginFocused = true;
  }else if(demoRoutes.indexOf(String(props.location.pathname)) > -1){
    demoFocused = true
  }

  const tabs = [
    { primaryAnchor: "login", secondaryAnchor: "signup", name: 'Sign In', href: '/login#login', icon: UserIcon, current: loginFocused },
    { primaryAnchor:"demo", secondaryAnchor: "demo", name: 'Demo', href: '/demo#demo', icon: PlayIcon, current: demoFocused }
  
  ]

  return (
    <div class="mx-4">
      <div className="">
        
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div class="">
              <a name={tab.primaryAnchor} />
              <a name={tab.secondaryAnchor} />

              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
              </div>
            ))}
          </nav>
          
        </div>
      </div>
      
    </div>
  )

}