import React, { useState, useEffect, Fragment, forceUpdate, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import Identicon from 'react-identicons';
import {isMobile} from 'react-device-detect';

import { useCookies } from 'react-cookie';
import LoadPlaceholder from '../../../util/auth';
import ReactJsTyping from 'reactjs-typing-effect';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import Countdown from 'react-countdown';

export default function QueuePanel(props) {
  const [time, setTime] = useState(Date.now());
  const [queueData, setQueueData] = useState({});
  const [teamPosition, setTeamPosition] = useState(-1);
  const [queueDidUpdate, setQueueDidUpdate] = useState(false);
  const [items, setItems] = useState([])
  const [topItem, setTopItem] = useState({id: -1, isUsersTeam: false, show: true})
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [fakeTeamNames, setFakeTeamNames] = useState([]);
  const [sessionID, setSessionID] = useState(null);
  const [gameReady, setGameReady] = useState(false);
  
  if(!sessionID){
    // alert("updating:" + String(props.sessionID))
    setSessionID(String(props.sessionID))
  }
  const fakeTextTypeTime = 7500
  var playingTeamTextList = ['Sending game data...','Pending runner...','Pending runner...','Pending runner...',
  'Pending runner...','Allocating runner...','Fetching runner ID...','Waiting for response...',
  'Getting team code...','Getting team code...','Getting team code...','Getting team code...',
  'Downloading libs...','Downloading libs...','Downloading libs...','Downloading libs...',
  'Installing libs...','Installing libs...','Installing libs...','Installing libs...','Installing libs...','Installing libs...', 
  'Starting game...','Starting game...','Starting game...']
  var numGames = Number((Math.floor(Math.random() * 5) + 1))
  var numGameMessages = Number((Math.floor(Math.random() * 10) + 1))
  for(var i = 1; i <= numGames; i++){
    for(var j; j < numGameMessages; j++){
      playingTeamTextList.push([`Running game #${numGames}...`])
    }
    playingTeamTextList.push([`Starting game #${numGames}...`])

  }
  playingTeamTextList.push([`Analyzing games...`])

  console.log("props")
  console.log(sessionID)
     


    useEffect(() => {
      // props.setGameState(1)
      if(!queueDidUpdate){
        // FETCH FAKE TEAM NAMES
        fetch("https://api.pacman.ai/names/?amount=1000")
        .then(response => response.json())
        .then((jsonData) => {
          var tmpNameList = []
        for(var i=0; i < jsonData.name.length; i++){
          tmpNameList.push(jsonData.name[i])
        }
        setFakeTeamNames(tmpNameList)
      })
        }

        const interval = setInterval(() => {

            // if(items[0].hasOwnProperty('id')){
            // }

            setTime(Date.now())

          
            fetchPositionInQueue()
            checkIfGameReady()

        }, 10000);

        return () => {
         
          clearInterval(interval);
          

        };


      }, []);

      function checkIfGameReady(){
        fetch(`https://job.pacman.ai/?sessionID=${sessionID}&serverID=client`,{ 
          method: 'GET',
          headers:  {
            'Accept': 'application/json',
            'msgID': 2

        }
      })
        .then(response => response.json())
        .then((jsonData) => {
          if(jsonData.data.success){
            if(jsonData.data.hasOwnProperty('msgID') && jsonData.data.msgID !== "null"){
              if(parseInt(jsonData.data.msgID) >= 10){
                props.setGameState(2, ()=> {
                  console.log("a")
                });

            }
          }
        }else if(jsonData.service === "KV" && (parseInt(jsonData.data.data.runnerStatus) > 0)){
          props.setGameState(2, ()=> {
                  console.log("a")
                });
        }
      })
      }


      useEffect(() => {
        if(items.length > 0){
          setTopItem({id:items[0].id, isUsersTeam: false, show: true})
        }

        const prevQueueData = cookies.prevQueueData || {};
        console.log('queue data pos')
        console.log(queueData.queuePosition)

        console.log('prev queue po')
        console.log(teamPosition)

          console.log('updated queue')
          console.log(items)

          if(items.length === 0 || teamPosition !== queueData.queuePosition){
            var tmpItems = []
            for (var i = 0; i <= queueData.inQueue; i++){
              tmpItems.push({id: i,  outClass: "transform transition duration-1000 sm:duration-2000", isTeamPosition: i === queueData.queuePosition})
              console.log(i)
            }
            setItems(tmpItems)
            // return

          }
          if(items.length === 0 || teamPosition !== queueData.queuePosition){
            const newItems = [...items];
  
            var tempItems = newItems.slice();
            const firstItem = tempItems.shift()
            if(firstItem !== undefined){
              setTopItem({id:firstItem.id, isUsersTeam: true, show: false})
            }else{
              return
            }
  
            if(tempItems[0]){
              tempItems[0].outClass = "transform transition delay-9000 duration-1000 sm:duration-2000"
            }
  
            console.log(tempItems)
            setItems(tempItems)
            
          }
          console.log("----------------------")
          console.log(queueData.queuePosition)
          setTeamPosition(queueData.queuePosition)


        }, [time]);

        useEffect(() => {
          if(items.length === queueData.inQueue){
            setQueueDidUpdate(true);
          }
              },[queueData]);


              useEffect(() => {
                if(gameReady){
                  // alert("hello")
                }
                    },[]);

                  //   useEffect(() => {
                  //     window.onbeforeunload = function() {
                  //       console.log('window realoded1')

                  //         return true;

                  //     };
                  //     console.log('window realoded3')

                  //     return () => {

                  //         window.onbeforeunload = null;
                  //     };

                  // }, []);
                  window.addEventListener('beforeunload', function (e) {
                    // the absence of a returnValue property on the event will guarantee the browser unload happens
                    console.log('window reloaded 22')
                    props.setGameState(99)
                    delete e['returnValue'];
                  });


async function fetchPositionInQueue(){

    console.log("fetching queue")
    console.log(sessionID)

    fetch("https://api.pacman.ai/getqueuepos/", { 
        method: 'GET',
        headers:  {
          'Accept': 'application/json',
          'sessionID': sessionID || "hello"
      },
    }).then((res) => res.json())
        .then((data) => {
          console.log("resp data")
         console.log(data);
          if(data.success){
            if(data.queueData.queuePosition === (queueData.queuePosition -1)){
              return
            }
            console.log(data.queueData.queuePosition)
            console.log(queueData.queuePosition)

            // State 1. see mainPanelContent.js for states.
            var gotQueueData = data.queueData
            var runners = 0
            var runnerPool = Object.keys(gotQueueData.serverPool)
            // CHANGE LATER WHEN ADDING TO API
            const avgRunnerperServer = 2
            console.log("runnerpool")
            console.log(runnerPool)
            for (var i = 0 ; i < runnerPool.length; i++){
              // console.log(runnerPool[i])
              if(!String(runnerPool[i]).includes("UNKNOWN")){
                runners+=1 ;
                runners *= 2
              }
            }
            gotQueueData.serverPool = runners
            console.log("runners")
            console.log(runners)
            setQueueData(gotQueueData)
          }
  
        })
        .catch((error) => {
          console.log("error");
  
          console.log(error);
        });


}

function getItems(){
  
  return items;
}

function teamWordExceedsMaxLength(name){
  if(!name){
    return false
  }
  console.log(isMobile)
  const maxLength = 20
  const nameWords = name.split(' ')
  for(var i = 0; i<nameWords.length;i++){
    if(nameWords[i].length >= maxLength){
      return true
    }
  }
  return false
}



return (


<div className="max-w-2xl w-full mx-auto sm:px-6 md:col-start-3 md:col-end-6">
<div className="space-y-3 py-8 ">
{console.log("console.log(topItem)")}
{console.log(topItem)}
{showGameInfoBanner()}


<Transition
          as={Fragment}
          enter="transform transition duration-1000 sm:duration-2000"
          enterFrom="translate-y-full opacity-10"
          enterTo="translate-y-0 opacity-100"
          leave="transform transition delay-1000 duration-1000 sm:duration-2000"
          leaveFrom="opacity-100"
          leaveTo="-translate-y-full opacity-10"
          show={topItem.show}
          >
        <div key={topItem.id} className={topItem.id === queueData.queuePosition ? 
          "bg-white p-4 sm:px-6 sm:rounded-lg sm:-mx-11 z-10 shadow-xl ring-2 ring-offset-2 ring-indigo-500 animate-bounce" :

          items.length > 0 && items[0].hasOwnProperty('id') ? 
          "bg-white sm:rounded-md shadow-md drop-shadow-md": "hidden"}
          >
                {/* <div class={topItem.id === queueData.queuePosition ? 
    "flex bg-gray-50 shadow-md p-4 rounded-lg drop-shadow-2xl ":
    "flex bg-gray-50 shadow-md p-4 rounded-lg drop-shadow-lg"}>
        <div data-placeholder className={topItem.id === queueData.queuePosition ?
        "mr-2 h-10 w-16 rounded-full overflow-hidden relative bg-gray-200 place-self-center " : 
        "mr-2 h-10 w-10 rounded-full overflow-hidden relative bg-gray-200 place-self-center"}>
        </div>
        <div class="flex flex-col place-self-center">
            <div data-placeholder class="mb-2 h-5 w-32 overflow-hidden relative bg-gray-200">
            
            </div>
            <div data-placeholder class="h-3 w-32 overflow-hidden relative bg-gray-200">
            
            </div>
        </div>
    </div> */}

    <div class="flex space-x-1">
    <Identicon className="rounded-md h-12 w-12 m-2" string={topItem.id === queueData.queuePosition ? cookies.teamName : fakeTeamNames[topItem.id]} size="400" count={5} bg="#ffffff"/>

    <div class="flex-1 space-y-2 pt-1">
      <div className={topItem.id === queueData.queuePosition ? teamWordExceedsMaxLength(cookies.teamName) : teamWordExceedsMaxLength(fakeTeamNames[topItem.id]) ? "text-lg font-medium text-gray-900 truncate capitalize text-left" : "text-sm font-medium text-gray-900 capitalize text-left"} > {topItem.id === queueData.queuePosition ? cookies.teamName : fakeTeamNames[topItem.id]}</div>
      <div class="space-y-2">
        <div class="h-4 rounded text-left text-gray-400 text-sm">
          <ReactJsTyping StringList={playingTeamTextList} speed={fakeTextTypeTime} /></div>
      </div>
    </div>

  </div>
  <span class="flex absolute h-4 w-4 top-1 -left-1 -mt-3 -ml-1 z-10">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-200 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-4 w-4 bg-yellow-300 opacity-75"></span>
      </span>
          {/* <h1 className="">Hello from item #{items.length >0 && items[0].hasOwnProperty('id') ? String(topItem.id) : null}</h1> */}
        </div>
        

        </Transition>
        </div>
        <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="px-2 bg-white text-sm text-gray-500">
        {/* <{items.length > 0 ? LoadPlaceholder : null}/> */}
        {items.length <= 0 ? <LoadPlaceholder/> : `In Queue | ${queueData.queuePosition}/${queueData.inQueue}` } 


          </span>
      </div>
    </div>
  <div className="space-y-3 py-8 ">
  {console.log("item data")}
            {console.log(items.length)}
            {console.log(items[0] !== undefined ? items[0].id : "items[0].id is undefined")}
            {console.log(topItem.id)}
      {getItems().map((item) => (
          <Transition
          as={Fragment}
          enter="transform transition duration-1000 sm:duration-2000"
          enterFrom="translate-y-full opacity-10"
          enterTo="translate-y-0 opacity-100"
          leave="transform transition duration-1000 sm:duration-2000"
          leaveFrom="opacity-100"
          leaveTo="-translate-y-full opacity-0"
          show={item.id === items[0].id ? false : true}
          >
            
        <div key={item.id} className={item.id === queueData.queuePosition? 
          "bg-white overflow-hidden p-4 sm:px-6 sm:rounded-lg sm:-mx-11 z-10 shadow-xl ring-2 ring-offset-2 ring-indigo-500 " :

          (items.length !== 0 && items[0].id !== topItem.id) || item.id !== topItem.id? 
          "bg-white sm:rounded-md shadow-md drop-shadow-md": "hidden"}
          >
              {/* //CARD */}
              {/* <div class="w-full flex items-center flex-col"> */}
              {/* <div class="border shadow rounded-md p-4 max-w-sm w-full mx-auto"> */}
  <div class="flex space-x-1 ">
    <Identicon className="rounded-md h-12 w-12 m-2" string={item.id === queueData.queuePosition ? props.gameType === "demo" ? "Your Awesome Team Name" : cookies.teamName : fakeTeamNames[item.id]} size="400" count={5} bg="#ffffff"/>

    <div class="flex-1 space-y-2 pt-1 truncate">
      <div className={item.id === queueData.queuePosition ? teamWordExceedsMaxLength(cookies.teamName) : teamWordExceedsMaxLength(fakeTeamNames[item.id]) ? "text-sm font-medium text-gray-900 truncate capitalize text-left" : "text-sm font-medium text-gray-900 capitalize text-left"} > {item.id === queueData.queuePosition ? props.gameType === "demo" ? "Your Awesome Team Name" : cookies.teamName : fakeTeamNames[item.id]}</div>
      <div class="space-y-2">
        <div class="h-4 rounded text-left text-gray-400 text-sm"> #{item.id} in queue</div>
      </div>
    </div>
  </div>

          {/* <h1 className="">Hello from item #{item.id}</h1> */}
        </div>

        </Transition>

      ))}
</div>

</div>
);

function showGameInfoBanner(){
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return 'Starting please wait.';
    } else {
      // Render a countdown
      if(hours){
        return <span>~{hours}h:{minutes}mins</span>;

      }else{
        return <span>~{minutes} mins</span>;

      }
    }
  };
  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-40">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-indigo-800">
                <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white truncate">
              
                <span className="inline md:hidden"> Est wait: {queueData && queueData.queuePosition && queueData.serverPool? 
                  <Countdown
                  intervalDelay={1}
                  zeroPadTime={1}
                  date={Date.now() + (450000 * (queueData.queuePosition / queueData.serverPool))
                  }
                  renderer={renderer}
                />
                : 'calculating...'}</span>
                {/* {alert(queueData.queueData)} */}
                <span className="hidden md:inline">Please be patient, each game can take up to 10 mins each. Est time: {queueData && queueData.queuePosition && queueData.serverPool? 
                <Countdown
                intervalDelay={1}
                zeroPadTime={1}
                date={Date.now() + (450000 * (queueData.queuePosition / queueData.serverPool))
                }
                renderer={renderer}
              />
              : 'calculating...'} </span>
              </p>
            </div>
            {/* <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"> */}
              {/* <a
                href="#"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
              >
                Learn more
              </a> */}
            {/* </div> */}
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              {/* <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
}
