import {BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom"
import { CookiesProvider, useCookies } from "react-cookie";

// Keep updating these routes as needed
import {LoginRoutes, 
	IndexRoutes,
	DemoRoutes, 
	CreateTeamRoute,
} from './util/routes';

import Index from './pages/index'
import CreateTeam from './pages/createTeam'

import PageNotFound from './pages/404'
export default function App(){
	// const [cookies, setCookie] = useCookies(['user']);
	// const userCookies = {cookies: cookies}

	return (
		<CookiesProvider>
  			<BrowserRouter>

					<Switch>

						<Route exact path="/signup/createTeam/" component={CreateTeam} />
						<Route exact path="/login" component={Index} />
						<Route exact path="/demo" component={Index} />
						<Route exact path="/faq" component={Index} />

						<Route exact path="/" component={Index} />



						{/* No route matched, show and redirect to 404  */}
						{/* <Route component={PageNotFound} />  */}
					</Switch>

			</BrowserRouter>
		</CookiesProvider>

		
	);
}
