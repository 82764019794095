
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { instanceOf } from "prop-types";
import { useCookies } from 'react-cookie';
import { Login } from 'heroicons-react';
import { authenticateTeam } from '../../util/auth';

import { Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

export default function LoginSignUp(){

  const [registerBtnText, setRegisterBtnText] = useState("GitHub");
  const [cookies, setCookie] = useCookies(['user']);

  const [teamName, setTeamName] = useState();
  const [teamPass, setTeamPass] = useState();

  const [showBadAuth, setShowBadAuth] = useState(false)

  const history = useHistory();

  async function login(e){
    e.preventDefault();

    authenticateTeam(teamName, teamPass).then((data) => {
      if(data.authenticated){
        setCookie('teamName', teamName, {path: '/'})
        setCookie('teamPass', teamPass, {path: '/'})
        
        history.push("/");
        window.location.reload();

      }else if(!data.authenticated){
        setShowBadAuth(true)

      }
    });
  }

  useEffect(() => {
  });
  
   function register(e){
      e.preventDefault();

      setRegisterBtnText("Registering...")
    fetch("https://api.pacman.ai/register", {
      method: 'POST',
      headers: new Headers(),
      body: JSON.stringify({ teamID: "communityName", selectedName: "files" })
  }).then((res) => res.json())
      .then((data) => {console.log(data)
        setRegisterBtnText("Redirecting...")
        window.open("https://github.com/apps/pacman-ai/installations/new?state=" + data.teamName);

    })
      .catch((err) => console.log(err))

}


  return (
    <>
            {showAuthError()}

    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your team</h2>

      <div className="overflow-hidden mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="">
          <form className="space-y-6" action="#" method="POST">
            <div class="text-left m-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Team Name
              </label>
              <div className="mt-1">
                <input
                  id="teamName"
                  name="teamName"
                  type="teamName"
                  autoComplete="teamName"
                  required
                  onChange={e => setTeamName(e.target.value)}
                  className="text-gray-800 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="text-left m-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Code
              </label>
              <div className="mt-1 ">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={e => setTeamPass(e.target.value)}
                  className="text-gray-800 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

          


            <div class="m-4">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white SignInBtn hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400"
                onClick={(e) => login(e)}
              >
                Sign in
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center ">
                <h3 className="px-2 bg-white text-l font-extrabold text-gray-900">Or sign up with</h3>
              </div>
            </div>

            <div className="mt-6">

              <div class="col-span-3 justify-items-center">
                
                <button
                  onClick={(e) => register(e)}
                  className="flex justify-center py-3 px-10 xs:px-15 sm:px-20 border rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 inline-flex border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-900 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign up with GitHub</span>
                  <svg className="ml-1 my-0.5 w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                      clipRule="evenodd"
                    />
                    
                  </svg>
                  <h2 className="ml-2 text-center text-xl font-extrabold text-gray-900">{registerBtnText}</h2>

                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );


  function showAuthError(){
    return <>
    {/* Global notification live region, render this permanently at the end of the document */}
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={showBadAuth}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">Auth failed!</p>
                  <p className="mt-1 text-sm text-gray-500">Teamname or teamcode was incorrect, try again.</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setShowBadAuth(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </>
  }
}
