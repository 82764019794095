import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function AdSection(props) {
  var advertisement;
  var displayStyle;
  var { width, height } = useWindowDimensions();
  var isDesktop = width > Number('1280')

  if(props.shouldShowAds){
    if(props.adType === "desktop" && isDesktop){
      // Will only show Desktop ads when on Desktop / large displays
      displayStyle = "hidden lg:block"
      advertisement = 
      <div class="flex justify-center p-16 pt-48">
        {/* <div class="text-black md:float-left box-content h-96 w-32 border-4"> */}
        <div class="text-black md:float-left box-content h-96 w-32">
          <div class="">
          {/* DESKTOP AD GOES HERE  */}
          </div>
        </div>
      </div>
    }else if(props.adType === "mobile" && !isDesktop){
      // Will only show Desktop ads when on Desktop / large displays
      displayStyle = "lg:hidden"
      advertisement =
      <section class="backgroundGradient b-border py-8">
        <div class="flex justify-center max-w-5xl mx-auto m-8">
          <div class="lg:hidden  backgroundGradient  border-b ">
            <div class="text-black">
              {/* MOBILE AD GOES HERE */}
            </div>
          </div>
        </div>
      </section>
    }
    return (
      <div class={"advertisement backgroundGradient flex justify-center  "}>
  
          {/* Desktop Ad */}
          {advertisement}
  
          {/* Mobile Ad  */}
          
          </div>
      );
  }else{
    return (      
      <div class="advertisement hidden"></div>
    );
  }
  

  
}
export default AdSection;

