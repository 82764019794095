import React, { useState, useEffect, Fragment } from 'react'

import CreatNewGameBanner from './mainPanel/createNewGameBanner'
import GameHistory from './mainPanel/gameHistory';
import CreateGamePanel from './mainPanel/createGamePanel'

import QueuePanel from './mainPanel/queuePanel';

import GamePanel from './mainPanel/gamePanel';
import { useCookies } from 'react-cookie';

import { leaveQueue } from '../../util/leaveQueue'

import { Transition } from '@headlessui/react'
import { CheckCircleIcon, UserAddIcon } from '@heroicons/react/outline'
import { XIcon} from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom';



export default function MainPanelContent(props) {

    const [showGameHistory, setShowGameHistory] = useState(false);
    const [gameHistoryLabelPrefix, setGameHistoryLabelPrefix] = useState("Show");   

    const [showCreateGameContent, setShowCreateGameContent] = useState(true);
    const [createGameLabelPrefix, setCreateGameLabelPrefix] = useState("Cancel");

    const [isViewingGame, setIsViewingGame] = useState(false);

    const [show, setShow] = useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [showTeamDetails, setShowTeamDetails] = useState(false)

    const [gameState, setGameState] = useState(0);

    const history = useHistory();

    // Game states
    // -1 => Player refreshed and left queue, show message.
    // 0 ==> None, no game state, at the stage of creating a new game
    // 1 ==> Created, user in queue.
    // 2 ==> Game has been picked up by a runner.
    // 3 ==> Game is complete.
    // 

    const [sessionID, setSessionID] = useState('');




    function toggleGameHistory(e){
        e.preventDefault();
        setShowGameHistory(!showGameHistory)

        setShowCreateGameContent(false)

        if(showGameHistory){
            setGameHistoryLabelPrefix("Show")
        }else{
            setGameHistoryLabelPrefix("Hide")

        }
    }

    function toggleCreateGame(e){
        // if(gameState === 3){
        //     setShowGameHistory(false)
        //     setGameState(0)
        // }
        e.preventDefault();
        
        setShowCreateGameContent(showCreateGameContent)
        setGameState(0)

        if(showGameHistory){
            setShowGameHistory(false)
            setGameHistoryLabelPrefix("Show")
        }else{
            setGameHistoryLabelPrefix("Hide")
        }

    }

    function shouldRenderGameHistory(){
        if(showGameHistory){
            return <GameHistory/>
        }else{
            return null
        }
    }

    function shouldRenderCreateGame(){
        // if should show and the game state is at 0, meaingin we are at the start of a new game cycle.
        if(showCreateGameContent && (gameState === 0 || gameState === -1)){
            // return null
            return <CreateGamePanel setGameState={setGameState} setSessionID={setSessionID} gameType={props.gameType}/>
        }else{
            return null
        }
    }

    function shouldRenderQueue(){
        // game is in queue
        if(gameState === 1){
            // return null
            var gameType = 'normal'
            if(window.location.href.indexOf("demo") > -1){
              
              gameType="demo"
            }

            return <QueuePanel setGameState={setGameState} gameState={gameState} sessionID={sessionID} gameType={gameType}/>
        }
    }

    function shouldRenderGame(){
        // game is starting or is finished.
        if(gameState === 2 || gameState === 3){
            // return <div>HELLO GAME </div>
            return <GamePanel sessionID={sessionID} gameType={props.gameType}/>
        }
    }

    useEffect(() => {
        console.log("GAME STATE")
        console.log(gameState)
        console.log("SESSION ID")
        console.log(sessionID)
    },[gameState, sessionID]);

    function showGameButtons(){
        return <div className="flex justify-between">
            <div class="flex justify-start">
        <button
            type="button"
            className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            // onClick={(e) => toggleGameHistory(e)}
        >
            <div className="hidden sm:block">
               {/* {gameHistoryLabelPrefix} Game History */}
               <dd className="mt-1 text-xl font-semibold text-gray-400 text-xs font-thin"> Game History Coming Soon</dd>

            </div>
            <div className="visible sm:hidden">
               {gameHistoryLabelPrefix} History
            </div>

        </button>
</div>
<div class="flex justify-end ">

        <button
            type="button"
            className="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(e) => toggleCreateGame(e)}
        >
            <div className="hidden sm:block">
                Create New Game
            </div>
            <div className="visible sm:hidden">
                New Game
            </div>
        </button>

        <button 
        type="button" 
        class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={(e) => logout(e)}
        >
        Logout
      </button>
      <UserAddIcon
      type="button"
      className="h-7 w-7 m-2 mx-4 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
        onClick={(e) => showTeamDetailsPanel(e)}
        />
        
      {/* </button> */}
    </div>
    
    </div>
    }

    function showTeamDetailsPanel(e){
        e.preventDefault();
        setShowTeamDetails(!showTeamDetails)
    }

    

    function logout(e){
        e.preventDefault();
        removeCookie('teamPass', { path: '/' });
        removeCookie('teamName', { path: '/' });
        history.push('/login#login')
        window.location.reload();

    }

    return (
        <div>
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30"
              >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition
                    show={showTeamDetails}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="p-4">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <UserAddIcon className="h-6 w-6 text-black" aria-hidden="true" />
                          </div>
                          <div className="ml-3 w-0 flex-1 pt-0.5 ">
                            <p className="text-sm font-medium text-gray-900">Invite People</p>
                            <p className="mt-1 text-sm text-gray-600">Share your team details:</p>
                            <p className="mt-1 text-sm text-gray-600 text-left">Team Name: '{cookies.teamName}'</p>
                            <p className="mt-1 text-sm text-gray-600 text-left">Team Code: '{cookies.teamPass}'</p>

                          </div>
                          <div className="ml-4 flex-shrink-0 flex">
                            <button
                              className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => {
                                setShowTeamDetails(false)
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            {gameState === 99 ? leaveQueue(sessionID, setGameState, gameState) && showLeftQueue() : null}
            {gameState === -1 ? showLeftQueue() : null}
            <div className="showNewGameHeader">
                {/* // Game state buttons */}

                {props.gameType !== "demo" ?
                showGameButtons() : <h1 className="text-black text-2xl font-black"> Demo</h1>}

                {/* // Game state buttons */}
            </div>
            <div className="mainPanelBody max-h-75 overflow-scroll">
                <div class="grid grid-cols-1 md:grid-cols-7 gap-4">
                    {/* // game GameHistory */}

                    {shouldRenderGameHistory()}
                    {shouldRenderCreateGame()}

                    {shouldRenderQueue()}
                    {shouldRenderGame()}

                </div>
            </div>

            {/* <h3>MAIN PANEL CONTENT GOES HERE :)</h3> */}
        </div>
    );

    function showLeftQueue(){
        return <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">Exited Queue!</p>
                      <p className="mt-1 text-sm text-gray-500">By refreshing the page, you have left the queue. This is done to prevent abusing the queueing system.</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    }
}




