import { SearchIcon } from '@heroicons/react/solid'
import TeamSelectionList from "./teamSelectionList"
import React, { useState, useEffect, Fragment, forceUpdate } from 'react';
import {isMobile} from 'react-device-detect';
import { useCookies } from 'react-cookie';
import Identicon from 'react-identicons';

/* This example requires Tailwind CSS v2.0+ */
function getHouseTeamWinRate(teamName){
    var min = 1;
    var max = 100;
    var rand = min + Math.random() * (max - min);
    var diff = 0;
    if(rand > 90){
        function genRand(min, max, decimalPlaces) {  
            var rand = Math.random()*(max-min) + min;
            var power = Math.pow(10, decimalPlaces);
            return Math.floor(rand*power) / power;
        }
        if(teamName === 'PacMan AI [Easy]'){
            return String(16.21 + genRand(0,2,2)) + '%'
        }else if(teamName === 'PacMan AI [Standard]'){
            return String(45.82 + genRand(0,2,2)) + '%'
        }else if(teamName === 'PacMan AI [Hard]'){
            return String(78.18 + genRand(0,2,2)) + '%'
        }
    }

    if(teamName === 'PacMan AI [Easy]'){
        return String(16.21) + '%'
    }else if(teamName === 'PacMan AI [Standard]'){
        return String(45.82) + '%'
    }else if(teamName === 'PacMan AI [Hard]'){
        return String(78.18) + '%'
    }

}

  
  export default function UserTeamSelectionList(props) {
    const [allTeams, setAllTimes] = useState([])
    const [teams, setTeams] = useState([])
    const [teamSelectionSize, setTeamSelectionSize] = useState(0)
    const [teamPageNumber, setTeamPageNumber] = useState(0)
    const [searchContents, setSearchContents] = useState('')
    const [createdTeam, setCreatedTeam] = useState(false)
    const [cookies, setCookie] = useCookies(['user']);
  
    // display 6 teams at once if on mobile else 9.
    const numPerPage = (isMobile ? 6 : 9)
    const numPerPageWithHouseTeams = (isMobile ? 3 : 6)

    var selectedTeam = false;

  useEffect(() => {
    const sTime = Date.now();

    fetch("https://api.pacman.ai/getTeamNames")
    .then(response => response.json())
    .then((jsonData) => {
      // jsonData is parsed json object received from url
      console.log(jsonData)

      var teamList = [];
      var teamSize = 0;
      for (var teamName in jsonData.teamData) {
        if(String(jsonData.teamData[teamName].teamName) != "Pacman AI [Easy]" && 
        String(jsonData.teamData[teamName].teamName) != "Pacman AI [Standard]" &&
        String(jsonData.teamData[teamName].teamName) != "Pacman AI [Hard]"){
          console.log(String(jsonData.teamData[teamName].teamName))
          teamList.push(jsonData.teamData[teamName]);
          teamSize+=1
        }

      }
      // FAKE PLACEHOLDER TEAM DATA
    //   fetch("https://api.pacman.ai/names/?amount=100000")
    //   .then(response => response.json())
    //   .then((jsonData) => {
    //   for(var i=0; i < jsonData.name.length; i++){
    //     teamList.push({
    //       teamName: jsonData.name[i],
    //       numberOfGamesPlayed: Number(((Math.floor(Math.random() * 500) + 6000) / 100).toFixed(2)),
    //       numberOfWins: Number(((Math.floor(Math.random() * 500) + 9000) / 100).toFixed(2))
    //     })

    //   }
    // })

      // END FAKE PLACEHOLDER TEAM DATA
      setTeamSelectionSize(teamSize);
      setAllTimes(teamList);
      setTeams(teamList.slice(0, numPerPageWithHouseTeams))
      console.log('Teamlist Actioned in: ' + String(parseFloat(Date.now() - sTime).toFixed(2)) + " ms.")

    })
    .catch((error) => {
      // handle your errors here
      console.log("error")
      console.error(error)
    })

    // if(teamPageNumber === 0){
    //   setTeams(allTeams.slice(0, 6));
    // }else{
    //   setTeams(allTeams);
    // }

    // console.log(allTeams)
  },[])

  useEffect(() => {
    console.log(searchContents)
    console.log(teams)
    console.log(teamPageNumber)
    console.log(teamSelectionSize)
    console.log('-----')
  }, [searchContents, teams, teamPageNumber, teamSelectionSize]);

  function pageAction(action, e){
    e.preventDefault();
    const sTime = Date.now()
    var newPageNumber = teamPageNumber
    if(action==="next"){
      setTeamPageNumber(teamPageNumber +1)
      newPageNumber = teamPageNumber +1
    }else if(action==="prev" && teamPageNumber > 0){
      setTeamPageNumber(teamPageNumber -1)
      newPageNumber = teamPageNumber -1

    }
    var offsetAmt = newPageNumber * numPerPage
    var teamList = [];
    var teamSize = 0

    for (var teamName in allTeams) {

      if(searchContents !== '' && action !== 'none'){
        if(action != 'none' && String(allTeams[teamName].teamName).toLowerCase().includes(searchContents.toLowerCase())){
          teamList.push(allTeams[teamName]);
          teamSize+=1;

        }
      }else{
        teamList.push(allTeams[teamName]);
        teamSize+=1;

      }
    }

    setTeamSelectionSize(teamSize)
    var teamsToShow;
    if((searchContents === '' || action === 'none') && newPageNumber === 0){
      teamsToShow = teamList.slice(0, numPerPageWithHouseTeams)
      

    }else{
      console.log("GETTING 9")
      teamsToShow = teamList.slice(offsetAmt, offsetAmt + numPerPage)

    }
    setTeams(teamsToShow)
   
    
    console.log(teamList.length)
    console.log("offset")
    console.log(offsetAmt)
    console.log(offsetAmt + numPerPage)
    console.log("-----")
    console.log(newPageNumber)
    console.log(teamsToShow)
    console.log('Nxt / Prv Actioned in: ' + String(parseFloat(Date.now() - sTime).toFixed(2)) + " ms.")
  }

  function search(e){
    const sTime = Date.now();
    var selectedName = e.target.value
    setSearchContents(selectedName);
    setTeamPageNumber(0)


    var teamList = [];
    var teamSize = 0;
    for (var teamName in allTeams) {

      if(String(allTeams[teamName].teamName).toLowerCase().includes(selectedName.toLowerCase())){
        teamList.push(allTeams[teamName]);
        teamSize+=1;
      }
    }
    setTeamSelectionSize(teamSize)



    if(selectedName.length === 0){
      pageAction('none',e)
      console.log("NON SEARCH")
    }else{
      var teamsToShow = '';
      var offsetAmt = teamPageNumber * numPerPage
      console.log(teamList)
      console.log('offset')
      console.log(offsetAmt)
      // if(teamPageNumber === 0){
      //   teamsToShow = teamList.slice(0, 6)
  
      // }else

      teamsToShow = teamList.slice(0, numPerPage)
      setTeams(teamsToShow)
      

    }

    console.log('Search Actioned in: ' + String(parseFloat(Date.now() - sTime).toFixed(2)) + " ms.")

  }


  function teamWordExceedsMaxLength(name){
    if(!name){
      return false
    }
    console.log(isMobile)
    const maxLength = 15
    const nameWords = name.split(' ')
    for(var i = 0; i<nameWords.length;i++){
      if(nameWords[i].length >= maxLength){
        return true
      }
    }
    return false
  }

  function submitGame(e, opponantTeamName){
    if(selectedTeam){
      return
    }
    selectedTeam = true
    e.preventDefault();

    const currentTeamName = cookies.teamName
    const currentTeamPass = cookies.teamPass
    const gameData = {"organization": String(props.org).toUpperCase(), "teamName": String(currentTeamName), "teamPassword": String(currentTeamPass), "redTeam": String(currentTeamName), "blueTeam": String(opponantTeamName), "randomSeed": String(props.randSeed), "map": String(props.map), "numberOfGames": String(props.rounds)};

    fetch("https://addJobToQueue.pacman.ai/", { 
      method: 'POST',
      headers:  {
        'Accept': 'application/json'
    },
      body: JSON.stringify(gameData)
  }).then((res) => res.json())
      .then((data) => {
        console.log("resp data")
       console.log(data);
        if(data.queueData.success && data.sessionID.length > 10){


          
          // State 1. see mainPanelContent.js for states.
          if(!createdTeam){

            setCreatedTeam(data.sessionID)

          }
        
          // setCookie('sessionID', String(data.sessionID), { path: '/' })
          // alert(JSON.stringify(data))
          
        }else{
          console.error("Error with creating game")
        }

      })
      .catch((error) => {
        console.log("error");

        console.log(error);
      });

  }


  useEffect(() => {
    if(String(createdTeam).length > 10 ){

    props.setGameState(1)
    props.setSessionID(createdTeam,
      function() { console.log("setSessionID State completed", createdTeam) })
    }
    }, [createdTeam]);

    return (
<div class="border border-gray-200 shadow-xl bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
   {/* // SEARCH CONTENT */}
   <div className="grid grid-cols-3 gap-4">
    < div className="text-left text-2xl font-semibold text-gray-900">
      Opponent Teams
    </div>
    <div className="col-start-3">
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="email"
          id="email"
          className="overflow-ellipsis focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search for a team"
          onChange={search.bind(this)}
        />
      </div>
    </div>
</div>

   {/* // SEARCH CONTENT */}
   </div>
  <div class="px-4 py-5 sm:p-6">
  {teamPageNumber == 0 && searchContents === ''? <TeamSelectionList map={props.map} org={String(props.org).toUpperCase()} randSeed={props.randSeed} rounds={props.rounds}  setGameState={props.setGameState} setSessionID={props.setSessionID} gameType={props.gameType}/> : null}

    {/* // MAIN TEAM SELECTION CONTENT */}
      
    {showUserTeams()}
    
    {/* // MAIN TEAM SELECTION CONTENT */}
    </div>
    {/* // PAGINATION CONTENT */}

    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{teamPageNumber <= 0 ? 1 :(teamPageNumber*numPerPage) + 1}</span> to <span className="font-medium">{props.gameType === "demo" ? 3 : teamPageNumber <= 0 ? numPerPage : (teamPageNumber*numPerPage) + teams.length}</span> of{' '}
          <span className="font-medium">{teamSelectionSize}</span> teams
        </p>
      </div>

      <div className="flex-1 flex justify-end">
        <a
          disabled={props.gameType === "demo" ? "true" : "false"}
          href="#"
          onClick={e => pageAction("prev", e)}
          className={(teamPageNumber === 0) ? "hidden":"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
        >
          Previous
        </a>
        <a
          disabled={props.gameType === "demo" ? "true" : "false"}
          href="#"
          onClick={e => pageAction("next", e)}
          className={((teamPageNumber*numPerPage) + teams.length >= teamSelectionSize ) ? "hidden":"ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white hover:bg-indigo-700 text-white bg-indigo-600"}
        >
          Next
        </a>
      </div>
    </nav>


    {/* // PAGINATION CONTENT */}
</div>

    )


    function showUserTeams(){
      if(props.gameType === "demo"){
        if(searchContents.length > 0){
          return <div className="grid grid-cols-1 gap-4">
            <h1 className="text-black text-l "> Cannot search for teams while demoing. </h1>
          </div>
        }
        return null
      }else{
        return <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        
        {teams.map((team) => (
          <div
            key={team.teamName}
            className="max-w-lg w-full sm:max-w-xs relative rounded-lg border border-gray-300 bg-white px-6 py-5 flex items-center space-x-3 hover:border-gray-400 hover:shadow-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            onClick={e => submitGame(e, team.teamName)}
          >
            <div className="flex-shrink-0">
            <Identicon className="h-10 w-10 rounded-md" string={team.teamName} size="400" count={5} bg="#ffffff"/>
            </div>
            <div className="flex-1 min-w-0">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0 " aria-hidden="true" />
  
                <p className={teamWordExceedsMaxLength(team.teamName) ? "text-sm font-medium text-gray-900 truncate capitalize" :"text-sm font-medium text-gray-900 capitalize"} >{team.teamName}</p>
                <p className="text-sm text-gray-500 truncate">Win rate: {Number(team.numberOfWins / team.numberOfGamesPlayed).toFixed(2)}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
      }

    }

  }
  